import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import * as texts from "@/assets/config.js";

import "@/assets/bootstrap.min.css";
import "@/assets/bootstrap.bundle.min.js";

//Set app
let app = createApp(App);
// global variables config
app.config.globalProperties.$texts = texts;
//mount app
app.use(store).use(router).mount("#app");
